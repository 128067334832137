import Link from "@mui/material/Link";

import theme from "../../theme";

type Props = {
  link: string;
  text: string;
};

const BaseUrlLink = ({ link, text }: Props) => (
  <Link
    href={link}
    target="_blank"
    rel="noreferrer"
    underline="none"
    color={theme.palette.primary.contrastText}
    fontSize={16}
  >
    {text}
  </Link>
);

export default BaseUrlLink;
