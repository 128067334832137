import { useEffect } from "react";

import { batch, useDispatch, useSelector } from "react-redux";

import { WebContainerPlatform } from "@elx-element/common/enums";
import { dispatchWrapperStateChangeEvent } from "@elx-element/common/events/dispatchers";

import { App, AppState } from "@capacitor/app";
import { Device } from "@capacitor/device";

import store from "../store";
import { selectPlatform } from "../store/main/selectors";
import { setPlatform, setPlatformIsActive } from "../store/main/slice";

import history from "../history";

const debug = window.env.webcontainer.ENABLE_DEBUG_LOG ?? false;

export type DevicePlatformInfo = {
  platform: WebContainerPlatform | undefined;
  isAndroid: boolean;
  isIos: boolean;
  isWeb: boolean;
};

/**
 * Hook pro nastavení informací o použité platformě.
 * Spuštění listeneru pro změnu stavu mobilní platformy.
 * Ošetření vypnutí mobilní aplikace při odhlášení.
 */
export const useDevicePlatformInfo = (): DevicePlatformInfo => {
  const dispatch = useDispatch();
  const platform = useSelector(selectPlatform);

  // listener na uzavření mobilní aplikace
  useEffect(() => {
    if (history.location.pathname.includes("/close")) {
      if (platform !== WebContainerPlatform.web) {
        if (debug) {
          console.debug("[Element Wrapper]: Exit application");
        }
        App.exitApp();
      }
    }
  }, [history]);

  useEffect(() => {
    // pokud platforma není nastavena, spustí se init listenerů - provádí se pouze jednou.
    if (platform === undefined) {
      batch(async () => {
        // zjištění platformy a uložení hodnoty do sessionStorage
        await Device.getInfo().then(x => {
          let devicePlatform: WebContainerPlatform = WebContainerPlatform.web;
          if (x.operatingSystem === WebContainerPlatform.android) {
            devicePlatform = WebContainerPlatform.android;
          }
          if (x.operatingSystem === WebContainerPlatform.ios) {
            devicePlatform = WebContainerPlatform.ios;
          }

          // uložení použité platformy do paměti aplikace
          dispatch(setPlatform(devicePlatform));

          // rovnou lze nastavit stav platformy jako aktivní
          dispatch(setPlatformIsActive(true));

          if (devicePlatform !== WebContainerPlatform.web) {
            // změna stavu aplikace - vypnutí displeje, přesun aplikace do pozadí
            App.addListener("appStateChange", (appStatus: AppState) => {
              if (debug) {
                console.debug("[Element Wrapper]: Element wrapper app state changed.", appStatus);
              }
              // události budu vyvolávat, jen pokud se nový stav liší s aktuálním stavem aplikace (výchozí ve state je active) (zabrání zdvojení posluchačů událostí)
              if (appStatus.isActive !== store.getState().main.platformIsActive) {
                // nastavíme poslední stav do store
                dispatch(setPlatformIsActive(appStatus.isActive));

                // vyvolání eventu o změně stavu aplikace. Tuto událost zpracovávají moduly samostatně.
                dispatchWrapperStateChangeEvent({ isActive: appStatus.isActive }, debug);
              }
            });
          }
        });
      });
    }
  }, [platform]);

  return {
    platform,
    isAndroid: platform === WebContainerPlatform.android,
    isIos: platform === WebContainerPlatform.ios,
    isWeb: platform === WebContainerPlatform.web,
  };
};
