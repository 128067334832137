/* eslint-disable import/prefer-default-export */
import { useEffect } from "react";

import { MachineLearning } from "capacitor-elinkx-machine-learning";
import {
  CameraPreviewOptions,
  ReplaceRule,
  ScanOptions,
  ScanResult,
} from "capacitor-elinkx-machine-learning/src/definitions";
import { ZebraScanner } from "capacitor-elinkx-zebra-scanner";
import { ZebraScanResult } from "capacitor-elinkx-zebra-scanner/src/definitions";
import { useDispatch, useSelector } from "react-redux";

import { EventCode, ScannerOption, ScanType } from "@elx-element/common/enums";
import { getConfigurationBoolValue, getConfigurationStringValue } from "@elx-element/common/envconf";
import {
  changeScannerControl,
  dispatchScannerEvent,
  registerScannerControlEventListener,
} from "@elx-element/common/events/scanner";
import { IScan, ScannerControl } from "@elx-element/common/events/types";
import { throwSpecificError } from "@elx-element/common/logger";
import { getLastGpsLocation, getLastGpsLocationToString } from "@elx-element/common/storage";

import { PluginListenerHandle } from "@capacitor/core/types/definitions";

import store from "../store";
import { selectPlatformIsActive } from "../store/main/selectors";
import { clearScanner, configureScanner, setScannerLastScan } from "../store/main/slice";

import { hideWebcontainerBackground } from "../components/app/core";
import { WebContainerErrors } from "../enums";

const zebraScannerContinualWork = getConfigurationBoolValue(window.env.webcontainer, "ZEBRA_SCANNER_CONTINUAL_WORK");
const zebraScannerDecoders = getConfigurationStringValue(window.env.webcontainer, "ZEBRA_SCANNER_DECODERS");

const debug = window.env.webcontainer.ENABLE_DEBUG_LOG ?? false;

// Základní konfigurace nahledu kamery
const cameraPreviewOptions: CameraPreviewOptions = {
  position: "rear",
  parent: "scanButtonContainer",
  className: "scanButtonContainer",
  toBack: true,
};

// handle eventu skenování pro potřebu ukončení naslouchání událostí skenování
let zebraScannerHandler: PluginListenerHandle;

// handle eventu skenování pro potřebu ukončení naslouchání událostí skenování
let scannerHandler: PluginListenerHandle;

/**
 * Obsluha pro události skenování.
 * Hook lze volat pouze jednou při spuštění aplikace.
 * @param enabled
 */
export const useScannerControl = (enabled: boolean) => {
  const dispatch = useDispatch();

  // Stav aplikace, pokud je uspána, minimalizována, pak je status false.
  const platformActive = useSelector(selectPlatformIsActive);

  // Na základě stavu aplikace přidávám a odebíram sledování výsledků ze zebra scanneru
  useEffect(() => {
    if (enabled && ZebraScanner !== undefined) {
      if (platformActive) {
        initZebraScanner();
      } else {
        clearZebraScanner();
      }
    }
  }, [platformActive, enabled]);

  /**
   * Inicializace posluchače událostí zebra scanneru
   */
  const initZebraScanner = async () => {
    if (zebraScannerContinualWork) {
      const sc = new ScannerControl();
      sc.active = false;
      sc.regex = undefined;
      sc.replaceRules = undefined;
      sc.name = undefined;
      sc.type = ScanType.zebraBarcode;

      // inicializuji posluchač
      zebraScannerHandler = await ZebraScanner.addListener("zebraScanEvent", (result: ZebraScanResult) =>
        zebraScanResultFunction(result, sc, onScanPerformed)
      );
    }

    // vytvořím profil dataWedge a nastavím základní nastavení scanneru (nezávislé na kontinuálním snímání)
    createDataWedgeProfile().then(() => {
      changeNativeZebraBehaviour(false);
    });
  };

  /**
   * Změna chování Zebra skeneru (vypnutí a zapnutí nativního chování skeneru - automatické skenování do focusovaného pole)
   * @param enableKeystroke povolit/zakázat nativní skenování
   */
  const changeNativeZebraBehaviour = (enableKeystroke: boolean) => {
    const zebraControl = new ScannerControl();
    zebraControl.active = false;
    zebraControl.type = ScanType.zebraBarcode;
    zebraControl.scannerOptions = [
      enableKeystroke ? ScannerOption.enableZebraKeystrokeOutput : ScannerOption.disableZebraKeystrokeOutput,
    ];
    changeScannerControl(zebraControl, debug);
  };

  /**
   * Uklizení posluchače událostí zebra scanneru
   */
  const clearZebraScanner = () => {
    if (zebraScannerContinualWork) {
      zebraScannerHandler.remove();
    }
  };

  /**
   *  Vytvoření profilu DataWedge a nastavení defaultních dekodérů
   */
  const createDataWedgeProfile = async () => {
    // todo: zde vyčíst konfiguraci dekodérů pro danou aplikaci
    try {
      await ZebraScanner.createProfile();
      if (debug) {
        console.debug("Zebra [scanner]: DataWedge profile created");
      }

      await ZebraScanner.setDecodersFromConfigString({ configString: zebraScannerDecoders });
      if (debug) {
        console.debug(`Zebra [scanner]: default encoders set ${zebraScannerDecoders}`);
      }
    } catch (ex) {
      throwSpecificError(
        WebContainerErrors.zebraProfileAndDecoderError,
        "Zebra [scanner]: profile or decoder setup error.",
        ex as string
      );
    }
  };

  /**
   * Po naskenování hodnoty volá event ScannerEvents.dispatchScannerEvent(event: Common.EventModels.IScanEvent)
   * Cílový modul událost zpracuje a může sám zavřít skenování, nebo nereagovat.
   * Pokud nereaguje pak může uživatel ručně vnutit naskenovaný kód tlačítkem "Použít".
   */
  const onZebraScanPerformed = (scan: IScan) => {
    dispatchScannerEvent({ type: EventCode.scanPerformed, scan });
  };

  /**
   * Handler změny state s nastavením Zebra skeneru
   * @param settings - nastavení skeneru ScannerControl
   * @param dispatch
   */
  const handleZebraScannerControlEvent = async (settings: CustomEventInit<ScannerControl>) => {
    if (debug) {
      console.debug(`nastavení skeneru:${JSON.stringify(settings.detail!)}`);
    }

    // uložíme poslední známé nastavení se kterým voláme řídící eventu (aby se při vypnutí v scanner.tsx vypínal správný modul)
    dispatch(configureScanner(settings.detail!));

    // Příchozí prametry
    // settings.detail!.active (boolean)
    // settings.detail!.name   (string | undefined)
    // settings.detail!.regex  (string | undefined)
    // settings.detail!.type   (Enums.ScanType (qr, ocr, photo, barcode, zebraBarcode, nfcMessage))

    // aktivace a deaktivace nativního Zebra vyplňování naskenovaných hodnot do focusovaných formulářových polí.
    if (
      settings.detail!.type === ScanType.zebraBarcode &&
      settings.detail!.scannerOptions !== undefined &&
      settings.detail!.scannerOptions.find(
        opt => opt === ScannerOption.enableZebraKeystrokeOutput || opt === ScannerOption.disableZebraKeystrokeOutput
      )
    ) {
      // zapnutí automatického skenování do označeného formulářového řádku
      if (settings.detail!.scannerOptions.find(opt => opt === ScannerOption.enableZebraKeystrokeOutput)) {
        ZebraScanner.setDataWedgeOptions({ keystroke_output_enabled: true }).then(() => {
          if (debug) {
            console.debug(`Zebra scaner: Native scaning behaviour scanner like a keyboard was enabled`);
          }
        });
      }
      // vypnutí automatického skenování do označeného formulářového řádku
      if (settings.detail!.scannerOptions.find(opt => opt === ScannerOption.disableZebraKeystrokeOutput)) {
        ZebraScanner.setDataWedgeOptions({ keystroke_output_enabled: false }).then(() => {
          if (debug) {
            console.debug(`Zebra scaner: Native scaning behaviour scanner like a keyboard was disabled`);
          }
        });
      }
    }

    // posluchač výsledků skenování se nachází v src\core\app.tsx nebo zde, dle nastavení zebraScannerContinualWork
    if (settings.detail!.active && settings.detail!.type === ScanType.zebraBarcode) {
      // v případě, že je zebra spuštěna nekontinuálně je listener inicializován přímo zde
      if (!zebraScannerContinualWork) {
        zebraScannerHandler = await ZebraScanner.addListener("zebraScanEvent", (result: ZebraScanResult) =>
          zebraScanResultFunction(result, settings.detail!, onZebraScanPerformed)
        );
      }

      // nastartování scanneru a spuštění skenování
      try {
        ZebraScanner.toggleSoftScanTrigger().then(() => {
          if (debug) {
            console.debug("Zebra scaner: Software scan button pressed");
          }
        });
      } catch (ex) {
        throwSpecificError(
          WebContainerErrors.zebraSoftwareScanError,
          "Zebra [scanner]: software trigger error.",
          ex as string
        );
      }
    }
    // v případě, že není zebra spuštěna kontinuálně musím zde ukončit listener
    else if (!zebraScannerContinualWork && zebraScannerHandler != null) {
      zebraScannerHandler.remove();
    }
  };

  /**
   * obsluha události naskenování nového výsledku
   * pro OCR se provádí ve třech fázích - 1.nahrazení výsledku dle kolekce RegExp pravidel, 2.rozpoznání obsahu dle kontrolního RegExp, 3.vyhodnocení výsledku
   * @param result - výsledek skenování typu scanResult
   * @param settings - state nastavení scaneru
   * @param onScanCompleted - funkce pro uložení výsledku skenování
   * @param onScanFinished - funkce pro uložení výsledku skenování s uzavřením scanneru
   */
  const zebraScanResultFunction = (
    result: ZebraScanResult,
    settings: ScannerControl,
    onScanCompleted: (scan: IScan) => void
  ) => {
    if (debug) {
      console.debug(`Zebra scanned value: ${JSON.stringify(result)}`);
      console.debug(`Zebra nastavení: ${JSON.stringify(settings)}z controlu: ${settings.name}`);
    }

    // zebra scanner může při kontinuálním snímání získat skenované pole pouze ze state, při stisku sw tlačítka ukládá do state to, co se aktuálně snažíme skenovat.
    // (Nevytváří se nový posluchač událostí skeneru, proto je při naskenování hodnoty v poli které se skenujeme nastavena hodnota undefined)
    // sw tlačítko ale vždy uloží do state informaci o tom, co právě teď skenuje, a po skenování by se tato hodnota měla vymazat. (pokud je zaplé kontinuální snímání)
    // po skenování by se měl předat prázdný výsledek, aby na to mohl strana klienta mohla zareagovat a odstranit listener těchto událostí
    const state = store.getState();

    // pokud nejde o výsledek informující o stavu skeneru
    if (
      (result.result === "ok" && result.type !== "statusInfo") ||
      (result.type === "statusInfo" && result.data.indexOf("WAITING") !== -1)
    ) {
      // získám poslední známou polohu z localStorage a sestavým výsledný objek
      const scan: IScan = {
        type: ScanType.zebraBarcode,
        value: result.data.indexOf("WAITING") === -1 ? result.data : undefined,
        gps: getLastGpsLocationToString(),
        name: settings.name !== undefined ? settings.name : state.main.scannerSettings?.name,
      };

      // předání výsledku registrovaným posluchačům
      onScanCompleted(scan);

      // náhlada za nativní funkci skeneru, kdy skenuju zebra skenerem bez definovaného name prvku, tedy pomocí hw tlačítka
      if (scan.name === undefined && scan.value !== undefined) {
        if (debug) {
          console.debug("Skenuju z pole undefined (name), hledám první pole skenovatelné zebra skenerem");
        }
        let selectedHTMLInput: HTMLInputElement | undefined;

        // 1. pokus o zjištění provku který má aktuálně focus, ten může být jen jeden
        const { activeElement } = document;
        if (activeElement && activeElement instanceof HTMLInputElement) {
          selectedHTMLInput = activeElement as HTMLInputElement;
          if (debug) {
            console.debug(`zebra[scanner]: vkladam dle focusu ${selectedHTMLInput?.name}`);
          }
        } else {
          // 2. žádný prvek nemá focus, dohledám ve stránce elementy typu data-scanableOnZebra
          const scanableElements = document.querySelectorAll("[data-ExternalScanPriority]");
          if (scanableElements && scanableElements.length > 0) {
            let minPriority: number = Number.MAX_VALUE;
            // zjištění pole s nejnižší prioritou
            // eslint-disable-next-line no-restricted-syntax
            for (const oneControl of Array.from(scanableElements)) {
              if (oneControl && oneControl instanceof HTMLInputElement) {
                const oneHTMLInputElement = oneControl as HTMLInputElement;
                const strPriority = oneHTMLInputElement.getAttribute("data-ExternalScanPriority");

                if (strPriority) {
                  const priority = parseInt(strPriority);
                  if (priority < minPriority) {
                    minPriority = priority;
                    selectedHTMLInput = oneHTMLInputElement;
                  }
                }
              }
            }

            if (selectedHTMLInput && debug) {
              console.debug(
                `zebra[scanner]: vkladam dle HTML atributu data-ExternalScanPriority ${selectedHTMLInput?.name}, s prioritou ${minPriority}`
              );
            }
          }
        }

        if (selectedHTMLInput && selectedHTMLInput.type === "text") {
          // jde o textové pole vrazím sem napřímo naši naskenovanou hodnotu + odpálíme událost změny hodnoty (aby mohl progarm zareagovat)
          // viz https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js
          if (debug) {
            console.debug(`zebra[scanner]: vkládám hodnotu ${scan.value} do elementu ${selectedHTMLInput?.name}`);
          }
          const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLInputElement.prototype,
            "value"
          )?.set;
          nativeInputValueSetter?.call(selectedHTMLInput, scan.value);

          const ev = new CustomEvent("input", {
            bubbles: true,
            detail: { scan: true },
          });
          selectedHTMLInput.dispatchEvent(ev);
        }
      }
    }

    // pokud příjde událost waiting, vyresetuju informaci o poli, které aktuálně skenuju a to tím, že přepíšu typ skenování
    if (
      settings.type === ScanType.zebraBarcode &&
      result.result === "ok" &&
      result.type === "statusInfo" &&
      result.data.indexOf("WAITING") !== -1
    ) {
      if (zebraScannerContinualWork) {
        const sc = new ScannerControl();
        sc.active = false;
        sc.regex = undefined;
        sc.replaceRules = undefined;
        sc.name = undefined;
        sc.type = ScanType.zebraBarcode;

        // přepíšeme nastavení tak aby name měl hodnotu undefined
        dispatch(configureScanner(sc));

        if (debug) {
          console.debug("Zebra nastavení skenování se mění na defaultní");
        }
      }
    }
  };

  /**
   * Handler změny state s nastavením NFC skeneru
   * @param settings - nastavení skeneru ScannerControl
   * @param dispatch
   */
  const handleNfcScannerControlEvent = (settings: CustomEventInit<ScannerControl>) => {
    // spuštění skeneru
    if (settings.detail!.active) {
      hideWebcontainerBackground();
      dispatch(configureScanner(settings.detail!));
    } else {
      // zavření skeneru
      scannerClose();
    }
  };

  /**
   * vrací konfiguraci podle aktuálního požadavku skenování
   * @param settings
   */
  const getCameraScanerOptions = (settings: ScannerControl): ScanOptions => {
    // udělám konverzi interface na interface pluginu
    const options: ScanOptions = {
      type: getCammeraScanOptionType(settings.type),
    };
    return options;
  };

  /** Konverze typu skenu */
  const getCammeraScanOptionType = (t: ScanType) => {
    switch (t) {
      case ScanType.cameraOcr:
        return "ocr";
      case ScanType.cameraBarcode:
        return "barcode";
      case ScanType.cameraQr:
        return "qr";
      default:
        return "photo";
    }
  };

  /**
   * Opakované spuštění scanneru, pokud je dostupné nastavení
   */
  const handleCameraScannerControlScanAgainEvent = (settings: ScannerControl | undefined) => {
    if (debug) {
      console.debug("[scanner]: opakované skenování tlačítkem");
    }
    try {
      if (settings) {
        // krátkodobý test scanu fotky
        const opt = getCameraScanerOptions(settings);
        MachineLearning.scan(opt);
      }
    } catch (ex) {
      throwSpecificError(
        WebContainerErrors.scannerScanAgainError,
        "[scanner]: error new scann try failed",
        ex as string
      );
    }
  };

  /**
   * Po naskenování hodnoty volá event ScannerEvents.dispatchScannerEvent(event: Common.EventModels.IScanEvent)
   * Cílový modul událost zpracuje a může sám zavřít skenování, nebo nereagovat.
   * Pokud nereaguje pak může uživatel ručně vnutit naskenovaný kód tlačítkem "Použít".
   */
  const onScanPerformed = (scan: IScan) => {
    // Příklad nastavení naskenovaného neznámého kódu
    dispatch(
      setScannerLastScan(
        scan /* {type: Enums.ScanType.ocr, gps: "49.56078023021972, 18.79304083346614", value: "1M8GDM9AXKP042788"} */
      )
    );
    dispatchScannerEvent({ type: EventCode.scanPerformed, scan }, debug);
  };

  /**
   * Po naskenování hodnoty volá event ScannerEvents.dispatchScannerEvent(event: Common.EventModels.IScanEvent)
   * Cílový modul zpracuje událost ,scanner se uzavírá automaticky skrz typ události Enums.EventCode.scanFinished
   */
  const onScanFinished = (scan: IScan) => {
    // Příklad nastavení naskenovaného neznámého kódu
    dispatch(
      setScannerLastScan(
        scan /* {type: Enums.ScanType.ocr, gps: "49.56078023021972, 18.79304083346614", value: "1M8GDM9AXKP042788"} */
      )
    );
    dispatchScannerEvent({ type: EventCode.scanFinished, scan }, debug);
  };

  /**
   * obsluha události změny orientace skeneru (kamery).
   */
  const deviceOrientationChange = () => {
    MachineLearning.isCameraActive().then((result: { value: boolean }) => {
      if (result.value) {
        if (debug) {
          console.debug("[scanner]: restart kamery při změně orientace obrazovky");
        }
        try {
          // vypnu a zapnu kameru
          MachineLearning.stop().then(() => {
            MachineLearning.start(cameraPreviewOptions);
          });
        } catch (ex) {
          throwSpecificError(
            WebContainerErrors.scannerOrientationChangeError,
            "[scanner]: camera restart failed when changing orientation",
            ex as string
          );
        }
      }
    });
  };

  /**
   * obsluha události naskenování nového výsledku
   * pro OCR se provádí ve třech fázích - 1.nahrazení výsledku dle kolekce RegExp pravidel, 2.rozpoznání obsahu dle kontrolního RegExp, 3.vyhodnocení výsledku
   * @param result - výsledek skenování typu scanResult
   * @param settings - state nastavení scaneru
   * @param onScanCompleted - funkce pro uložení výsledku skenování
   * @param onScanFinished - funkce pro uložení výsledku skenování s uzavřením scanneru
   */
  const scanResultFunction = (
    result: ScanResult,
    settings: ScannerControl,
    onScanCompletedCallback: (scan: IScan) => void,
    onScanFinishedCallback: (scan: IScan) => void
  ) => {
    if (debug) {
      console.debug(`[scanner]: scanned value: ${JSON.stringify(result)}`);
    }

    if (result.result === "ok") {
      let lastGpsLocationString: string | undefined;

      // získám poslední známou polohu z localStorage
      const lastGpsLocation = getLastGpsLocation();
      if (lastGpsLocation) {
        lastGpsLocationString = `${lastGpsLocation.position}, acc: ${parseInt(lastGpsLocation.accuracy!)}`;
      }

      if (result.type === ScanType.cameraOcr.valueOf()) {
        let { data } = result;

        // 1. upravíme data dle nastavených pravidel
        if (settings && settings.replaceRules) {
          try {
            const rules = settings.replaceRules as Array<ReplaceRule>;
            rules.forEach(rule => {
              if (rule.key === "(UPPERCASE)") {
                // speciální pravidlo pro UPPERCASE
                data = data.toUpperCase();
              } else {
                const regexp = new RegExp(rule.key, "g");
                data = data.replace(regexp, rule.value);
              }

              if (debug) {
                console.debug(`RegExp rule applied: '${rule.key}' -> '${rule.value}' result: ${data}`);
              }
            });
          } catch (ex) {
            throwSpecificError(
              WebContainerErrors.scannerRegexpReplaceError,
              "[scanner]: result regexp replace error",
              ex as string
            );
          }
        }

        // 2. detekujeme shodu s požadovaným regexpem (výsledkem je pole hodnot)
        let results: RegExpMatchArray | null = null;
        try {
          if (!settings.regex) {
            throw Error("Regexp definition in scanner settings is undefined");
          }
          if (debug) {
            console.debug(`[scanner]: regexp ${settings.regex}`);
          }

          const regex = new RegExp(settings.regex, "g");
          results = data.match(regex);
          if (debug) {
            console.debug(`[scanner]: regexp result ${JSON.stringify(results)}`);
          }
        } catch (ex) {
          throwSpecificError(WebContainerErrors.scannerRegexpError, "[scanner]: scanner regexp error", ex as string);
        }

        // 3. zpracování výsledků
        if (results !== null && results.length > 0) {
          let retVal: string | undefined;
          if (results.length > 0) {
            // použiju vždy první detekovanou hodnotu
            [retVal] = results;
          }

          // 4. odeberu z výsledku mezery
          // V momentě kdy bude potřeba skenovat data s mezerami, zavedeme novou sadu pravidel
          // obdobně jako v settings.replaceRules pro převod naskonované hodnoty na dataFormat.
          if (retVal != null) {
            retVal = retVal.replace(/\s/g, "");
          }
          if (debug) {
            console.debug(`[scanner]: result:${retVal}`);
          }

          onScanCompletedCallback({
            name: settings.name,
            type: ScanType.cameraOcr,
            gps: lastGpsLocationString,
            value: retVal,
          });
        } else {
          // opakovaný scann, v případě nenalezení dat
          try {
            if (debug) {
              console.debug("[scanner]: regexp not detected, automatic retry attempt");
            }
            MachineLearning.scan(getCameraScanerOptions(settings));
          } catch (ex) {
            throwSpecificError(
              WebContainerErrors.scannerNotFoundScanError,
              "[scanner]: error new scann try failed",
              ex as string
            );
          }
        }
      }

      // zpracování fotky
      if (result.type === ScanType.cameraPhoto.valueOf()) {
        onScanFinishedCallback({
          name: settings.name,
          type: ScanType.cameraPhoto,
          gps: lastGpsLocationString,
          value: result.data,
        });
      }

      // zpracování barcode výsledku
      if (result.type === ScanType.cameraBarcode.valueOf()) {
        if (result !== null && result.data.length > 0) {
          if (debug) {
            console.debug(`[scanner]: barcode result:${result.data}`);
          }

          onScanCompletedCallback({
            name: settings.name,
            type: ScanType.cameraBarcode,
            gps: lastGpsLocationString,
            value: result.data,
          });
        } else {
          // opakovaný scann, v případě prázdných dat (Firebase může při prvním scanu vrátit prázdný výsledek)
          try {
            if (debug) {
              console.debug("[scanner]: barcode not detected, automatic retry attempt");
            }
            MachineLearning.scan(getCameraScanerOptions(settings));
          } catch (ex) {
            throwSpecificError(
              WebContainerErrors.scannerNotFoundScanError,
              "[scanner]: error new barcode scann try failed",
              ex as string
            );
          }
        }
      }

      // zpracování 2d barcode / qr code výsledku
      if (result.type === ScanType.cameraQr.valueOf()) {
        if (result !== null && result.data.length > 0) {
          if (debug) {
            console.debug(`[scanner]: barcode result:${result.data}`);
          }

          onScanCompletedCallback({
            name: settings.name,
            type: ScanType.cameraQr,
            gps: lastGpsLocationString,
            value: result.data,
          });
        } else {
          // opakovaný scann, v případě prázdných dat (Firebase může při prvním scanu vrátit prázdný výsledek)
          try {
            if (debug) {
              console.debug("[scanner]: 2d barcode / qr code not detected, automatic retry attempt");
            }
            MachineLearning.scan(getCameraScanerOptions(settings));
          } catch (ex) {
            throwSpecificError(
              WebContainerErrors.scannerNotFoundScanError,
              "[scanner]: error new 2d barcode / qr code scann try failed",
              ex as string
            );
          }
        }
      }
    }
  };

  /**
   * otevření náhledu scanneru a zahájení prvního skenování dle nastavení
   * @param settings - nastavení skenování
   * @param dispatch
   */
  const scannerOpen = async (settings: ScannerControl) => {
    dispatch(configureScanner(settings));
    hideScannerPageBackground();

    // inicializace posluchače HW skenování
    scannerHandler = await MachineLearning.addListener("scanEvent", (result: ScanResult) =>
      scanResultFunction(result, settings, onScanPerformed, onScanFinished)
    );

    // inicializace posluchače otáčení obrazovky
    window.addEventListener("orientationchange", deviceOrientationChange);

    // nastartování scanneru a spuštění skenování
    try {
      MachineLearning.isCameraActive().then((result: { value: boolean }) => {
        if (result.value) {
          if (settings.type !== ScanType.cameraPhoto) {
            MachineLearning.scan(getCameraScanerOptions(settings));
          }
        } else {
          MachineLearning.start(cameraPreviewOptions).then(() => {
            if (settings.type !== ScanType.cameraPhoto) {
              MachineLearning.scan(getCameraScanerOptions(settings));
            }
          });
        }
      });
    } catch (ex) {
      throwSpecificError(WebContainerErrors.scannerNotFoundScanError, "[scanner]: scan error", ex as string);
    }
  };

  /**
   * zavření náhledu scanneru
   * @param dispatch
   */
  const scannerClose = () => {
    // odebrání posluchače otáčení obrazovky
    window.removeEventListener("orientationchange", deviceOrientationChange);

    // odebrání posluchače HW skenování
    if (scannerHandler != null) {
      scannerHandler.remove();
    }

    // zastavení scann pluginu pokud je aktivní
    MachineLearning.isCameraActive().then((result: { value: boolean }) => {
      if (result.value) {
        if (debug) {
          console.debug("[scanner]: Probíhá vypnutí kamery při uzavření skeneru.");
        }
        try {
          // vypnu kameru
          MachineLearning.stop();
        } catch (ex) {
          throwSpecificError(
            WebContainerErrors.scannerShutdownError,
            "[scanner]: scanner shutdown error",
            ex as string
          );
        }
      }
    });

    dispatch(clearScanner());
    showScannerPageBackground();
  };

  /**
   * posluchač změny state s nastavením skeneru
   * @param settings - nastavení skeneru ScannerControl
   * @param dispatch
   */
  const handleCameraScannerControlEvent = (settings: CustomEventInit<ScannerControl>) => {
    // Příchozí prametry
    // settings.detail!.active (boolean)
    // settings.detail!.name   (string | undefined)
    // settings.detail!.regex  (string | undefined)
    // settings.detail!.type   (Enums.ScanType)

    // spuštění skeneru
    if (settings.detail!.active) {
      scannerOpen(settings.detail!);
    } else {
      // zavření skeneru
      scannerClose();
    }
  };
  /**
   * Listener změny state s nastavením skeneru
   * @param settings - nastavení skeneru ScannerControl
   * @param dispatch
   */
  const handleScannerControlEvent = (
    scannerNewSettings: CustomEventInit<ScannerControl>,
    scannerActualSettings: ScannerControl | undefined
  ) => {
    // Příchozí prametry
    // settings.detail!.active (boolean)
    // settings.detail!.name   (string | undefined)
    // settings.detail!.regex  (string | undefined)
    // settings.detail!.type   (Enums.ScanType)

    if (scannerActualSettings === scannerNewSettings) {
      return;
    }

    if (
      scannerNewSettings.detail!.active === false &&
      scannerNewSettings.detail!.type === undefined &&
      scannerActualSettings
    ) {
      // eslint-disable-next-line no-param-reassign
      scannerNewSettings.detail!.type = scannerActualSettings!.type;
    }

    switch (scannerNewSettings.detail!.type) {
      case ScanType.zebraBarcode:
        handleZebraScannerControlEvent(scannerNewSettings);
        break;
      case ScanType.nfcMessage:
        handleNfcScannerControlEvent(scannerNewSettings);
        break;
      case ScanType.scanAgain:
        handleCameraScannerControlScanAgainEvent(scannerActualSettings);
        break;
      default:
        handleCameraScannerControlEvent(scannerNewSettings);
        break;
    }
  };

  /**
   * Všechny komponenty na stránce musejí být zneviditelněny, protože komponenta fotoaparátu se renderuje pod stránku s aplikací.
   */
  const hideScannerPageBackground = () => {
    document.getElementById(`${process.env.REACT_APP_NAME}_root`)!.classList.add("hidden");
    document.querySelectorAll("[role='presentation']").forEach(e => {
      if (e.getAttribute("data-app") !== process.env.REACT_APP_NAME) {
        e.classList.add("hidden");
      }
    });
    document.body.classList.add("transparent");
  };

  /**
   * Po zavření scanneru opět zviditelníme všechny části aplikace.
   */
  const showScannerPageBackground = () => {
    document.getElementById(`${process.env.REACT_APP_NAME}_root`)!.classList.remove("hidden");
    document.querySelectorAll("[role='presentation']").forEach(e => {
      e.classList.remove("hidden");
    });
    document.body.classList.remove("transparent");
  };

  useEffect(() => {
    if (enabled) {
      // Listener na ovládání komponenty scanneru je aktivní pouze při přístupu z mobilní aplikace
      registerScannerControlEventListener(event =>
        handleScannerControlEvent(event, store.getState().main.scannerSettings)
      );
    }
  }, [enabled]);
};
