import React, { useEffect, useMemo } from "react";

import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";

import TreeView from "@mui/lab/TreeView";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useElementContext } from "@elx-element/common/elementContext";
import {
  dispatchSubscribeNotificationByCodeEvent,
  dispatchUnsubscribeNotificationByCodeEvent,
} from "@elx-element/common/events/dispatchers";
import { cloneArray } from "@elx-element/common/utils";
import BaseIcon from "@elx-element/ui/DataDisplay/BaseIcon";

import { mdiChevronDown, mdiChevronRight, mdiContentCopy } from "@mdi/js";

import {
  ApplicationEventModel,
  // eslint-disable-next-line camelcase
  NotificationSubcriptionModel_OptDirection,
} from "../../types.generated";

import { selectApplicationEvents, selectSubcriptions } from "../../store/message/selectors";

import useTexts from "../../hooks/useTexts";

import useStyles, { StyledTreeItem } from "./styles";

interface RenderTree {
  code: string;
  parentCode: string;
  description?: string;
  children?: RenderTree[];
  source: string;
}

const User = () => {
  const { classes } = useStyles();
  const texts = useTexts();
  const auth = useAuth();
  const applicationEvents: Array<ApplicationEventModel> = useSelector(selectApplicationEvents);
  const subscriptions = useSelector(selectSubcriptions);
  const [trees, setTrees] = React.useState<RenderTree[]>([]);
  const [sourceCodes, setSourceCodes] = React.useState<string[]>([]);
  const [expanded, setExpanded] = React.useState<Array<string>>([]);
  const theme = useTheme();
  const viewPortMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getParsedAuthToken } = useElementContext();
  const parsedAuthToken = getParsedAuthToken();

  useEffect(() => {
    if (sourceCodes.length === 0) {
      const tempSources: Array<string> = [];
      applicationEvents.forEach(x => {
        if (!tempSources.includes(x.source!)) {
          tempSources.push(x.source!);
        }
      });
      setSourceCodes(tempSources);
    } else {
      createRenderTrees();
    }
  }, [applicationEvents, subscriptions]);

  /**
   * Převedení listu objektů na strom
   * @param list
   */
  const listToTree = (list: any): RenderTree[] => {
    const map = {} as any;
    let node;
    const roots = [];
    let i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].code] = i; // initialize the map
      // eslint-disable-next-line no-param-reassign
      list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parentCode !== "" && map[node.parentCode]) {
        // if you have dangling branches check that map[node.parentId] exists
        list[map[node.parentCode]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  };

  /**
   * Z dostupných dat vytvoří stromovou strukturu její nastaví stav
   */
  const createRenderTrees = () => {
    const newTrees: RenderTree[] = [];
    // pro každý zdroj renderujeme samostatný strom

    sourceCodes.forEach(source => {
      const sourceEventsList: Array<RenderTree> = [];
      applicationEvents
        .filter(x => x.source === source)
        .forEach(s => {
          const parentCode = s.code!.substring(0, s.code!.lastIndexOf("."));
          sourceEventsList.push({
            code: s.code!,
            parentCode,
            description: s.description ?? "",
            children: [],
            source: s.source!,
          });
        });

      newTrees.push({
        code: source,
        parentCode: "",
        description: source,
        children: listToTree(sourceEventsList),
        source,
      });
    });

    setTrees(newTrees);
    setExpanded(getExpandedNodes());
  };

  const getOnChange = (checked: boolean, nodes: RenderTree) => {
    if (checked) {
      dispatchSubscribeNotificationByCodeEvent(nodes.code);
    } else {
      dispatchUnsubscribeNotificationByCodeEvent(nodes.code);
    }
  };

  /**
   * Vraci seznam expandovaných nodů
   */
  const getExpandedNodes = (): Array<string> => {
    const expandedCodes: Array<string> = cloneArray(sourceCodes);
    const codes = subscriptions.map(x => x.eventCodeGroup!);
    codes.forEach(code => {
      while (code.indexOf(".") > -1) {
        expandedCodes.push(code.substring(0, code.lastIndexOf(".")));
        // eslint-disable-next-line no-param-reassign
        code = code.substring(0, code.lastIndexOf("."));
      }
    });
    return expandedCodes;
  };

  const renderTree = (nodes: RenderTree) => (
    <StyledTreeItem
      key={nodes.code}
      nodeId={nodes.code}
      label={
        <FormControlLabel
          classes={{ label: classes.treelabel }}
          control={
            <Checkbox
              color="secondary"
              checked={subscriptions.some(
                item =>
                  item.eventCodeGroup === nodes.code &&
                  item.direction === NotificationSubcriptionModel_OptDirection.OptIn
              )}
              onChange={event => getOnChange(event.currentTarget.checked, nodes)}
              onClick={e => e.stopPropagation()}
              disabled={sourceCodes.includes(nodes.code)}
              className={sourceCodes.includes(nodes.code) ? classes.hiddenNode : undefined}
              indeterminate={subscriptions.some(
                item =>
                  item.eventCodeGroup === nodes.code &&
                  item.direction === NotificationSubcriptionModel_OptDirection.OptOut
              )}
              title={
                subscriptions.some(
                  item =>
                    item.eventCodeGroup === nodes.code &&
                    item.direction === NotificationSubcriptionModel_OptDirection.OptOut
                )
                  ? texts.STOP_SHOW
                  : ""
              }
            />
          }
          label={
            <Tooltip title={nodes.code} placement="right">
              <div className={sourceCodes.includes(nodes.code) ? classes.rootLabel : undefined}>{nodes.code}</div>
            </Tooltip>
          }
          key={nodes.code}
        />
      }
    >
      {Array.isArray(nodes.children) ? nodes.children.map(node => renderTree(node)) : null}
    </StyledTreeItem>
  );

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const getRoles = () => (parsedAuthToken?.realm_access?.roles ?? []).sort();

  const memoizedTrees = useMemo(
    () =>
      !viewPortMobile && trees.length > 0 ? (
        <>
          <Typography component="div" color="secondary" className={classes.headding}>
            {texts.SUBSCRIBE_SETTINGS}
          </Typography>
          {trees.map(sourceTree => (
            <TreeView
              onNodeToggle={handleToggle}
              key={sourceTree.source}
              defaultCollapseIcon={<BaseIcon data={mdiChevronDown} />}
              defaultExpandIcon={<BaseIcon data={mdiChevronRight} />}
              expanded={expanded}
            >
              {renderTree(sourceTree)}
            </TreeView>
          ))}
        </>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
      ),
    [viewPortMobile, trees, expanded]
  );

  return (
    <div className={classes.root}>
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <b>{texts.USERNAME}</b>
              </TableCell>
              <TableCell colSpan={3} align="left">
                {parsedAuthToken?.preferred_username}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                <b>{texts.EMAIL}</b>
              </TableCell>
              <TableCell colSpan={3} align="left">
                {parsedAuthToken?.email}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                <b>{texts.REALM_ACCESS}</b>
              </TableCell>
              <TableCell align="left" valign="top" style={{ verticalAlign: "top" }}>
                {getRoles()
                  .filter((_x: any, i: number) => i <= 30)
                  .map((a: string, i: number) => (
                    <div key={i}>{a}</div>
                  ))}
              </TableCell>
              <TableCell align="left" valign="top" style={{ verticalAlign: "top" }}>
                {getRoles()
                  .filter((_x: any, i: number) => i > 30 && i <= 60)
                  .map((a: string, i: number) => (
                    <div key={i}>{a}</div>
                  ))}
              </TableCell>
              <TableCell align="left" valign="top" style={{ verticalAlign: "top" }}>
                {getRoles()
                  .filter((_x: any, i: number) => i > 60)
                  .map((a: string, i: number) => (
                    <div key={i}>{a}</div>
                  ))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" colSpan={4}>
                {auth.user?.access_token && (
                  <Button
                    startIcon={<BaseIcon data={mdiContentCopy} />}
                    onClick={() => navigator.clipboard.writeText(auth.user!.access_token)}
                  >
                    {texts.COPY_TOKEN}
                  </Button>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {memoizedTrees}
    </div>
  );
};
export default User;
