/* eslint-disable no-param-reassign */
import { NetworkStatus, NetworkStrength, NetworkType, WebContainerPlatform } from "@elx-element/common/enums";
import { getConfigurationStringValue } from "@elx-element/common/envconf";
import { dispatchCultureChangeEvent, dispatchNetworkStatusChangeEvent } from "@elx-element/common/events/dispatchers";
import { IScan, NfcWriterControl, ScannerControl } from "@elx-element/common/events/types";
import {
  getAppInitialCulture,
  getMachineSettings,
  getStoredNetworkStatus,
  setMachineSettings as storeMachineSettings,
  storeCulture,
  storeNetworkStatus,
  storeNetworkStrength,
  storeNetworkType,
  storePlatform,
} from "@elx-element/common/storage";
import { MachineSettings } from "@elx-element/common/storage/types";
import { ModuleInterface, ModuleInterfaceEvent, RegisteredModule } from "@elx-element/common/types";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RouteModel } from "../../types";
import { DashboardSettingsModel } from "../../types.generated";

import { AudioEventType } from "../../hooks/useSoundEvents";

import CallbackPage from "../../components/auth/CallbackPage";
import LoginPage from "../../components/auth/LoginPage";
import LogoutPage from "../../components/auth/LogoutPage";
import Dashboard from "../../components/dashboard";
import User from "../../components/user";

const debug = window.env.webcontainer.ENABLE_DEBUG_LOG ?? false;
const initialCulture = getAppInitialCulture(process.env.REACT_APP_NAME!);
const initialNetworkStatus = getStoredNetworkStatus();

export interface mainState {
  cultures: Array<string>;
  culture: string;
  routes: Array<RouteModel>;
  networkStatus: NetworkStatus;
  networkStrength: NetworkStrength;
  networkType: NetworkType;
  machineSettings: MachineSettings;
  infoPopupOpen: boolean;
  mainMenuOpen: boolean;
  registeredModules: Array<RegisteredModule>;
  registeredModulesLoaded: boolean;
  activeModuleId: string | undefined;
  platform: WebContainerPlatform | undefined;
  platformIsActive: boolean;
  scannerSettings: ScannerControl | undefined;
  scannerLastScan: IScan | undefined;
  nfcWriterSettings: NfcWriterControl | undefined;
  dashboardSettings: Array<DashboardSettingsModel>;
  openErrorDetailReference: string | undefined;
  webContainerUpdated: boolean | undefined;
  moduleUpdated: boolean | undefined;
  audioBuffer: Array<AudioEventType>;
}

const initialState: mainState = {
  cultures: getConfigurationStringValue(window.env[process.env.REACT_APP_NAME!], "CULTURES").split(";"),
  culture: initialCulture,
  routes: [
    { route: "callback", component: CallbackPage, private: false, default: false },
    { route: "login", component: LoginPage, private: false, default: false },
    { route: "logout", component: LogoutPage, private: false, default: false },
    { route: "dashboard", component: Dashboard, private: true, default: true },
    { route: "user", component: User, private: true, default: false },
  ],
  networkStatus: window.inCapacitor ? NetworkStatus.offline : NetworkStatus.online,
  networkType: window.inCapacitor ? NetworkType.none : NetworkType.browser,
  networkStrength: window.inCapacitor ? NetworkStrength.none : NetworkStrength.good,
  machineSettings: getMachineSettings(),
  infoPopupOpen: false,
  mainMenuOpen: false,
  registeredModules: [],
  registeredModulesLoaded: false,
  activeModuleId: undefined,
  platform: undefined,
  platformIsActive: true,
  scannerSettings: undefined,
  scannerLastScan: undefined,
  nfcWriterSettings: undefined,
  dashboardSettings: [],
  openErrorDetailReference: undefined,
  webContainerUpdated: undefined,
  moduleUpdated: undefined,
  audioBuffer: [],
};

// Uložení kultury do sessionStorage pro předání modulům
storeCulture(initialCulture);

// Nastavení stavu aplikace - offline stav: aplikace obsahuje neodeslaná data, je potřeba dokončit datovou výměnu při připojení k síti
storeNetworkStatus(initialNetworkStatus === null ? NetworkStatus.online : initialNetworkStatus);

const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setCulture: (state, action: PayloadAction<string>) => {
      storeCulture(action.payload);
      dispatchCultureChangeEvent(debug);
      state.culture = action.payload;
    },
    setNetworkStatus: (state, action: PayloadAction<NetworkStatus>) => {
      storeNetworkStatus(action.payload);
      dispatchNetworkStatusChangeEvent(debug);
      state.networkStatus = action.payload;
    },
    setNetworkType: (state, action: PayloadAction<NetworkType>) => {
      storeNetworkType(action.payload);
      state.networkType = action.payload;
    },
    setNetworkStrength: (state, action: PayloadAction<NetworkStrength>) => {
      storeNetworkStrength(action.payload);
      state.networkStrength = action.payload;
    },
    setMachineSettings: (state, action: PayloadAction<MachineSettings>) => {
      storeMachineSettings(action.payload);
      state.machineSettings = action.payload;
    },
    setInfoPopupOpen: (state, action: PayloadAction<boolean>) => {
      state.infoPopupOpen = action.payload;
    },
    // Akce pro uložení příznaku otevřeného menu
    setMainMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.mainMenuOpen = action.payload;
    },
    // Akce pro uložení ID aktuálně spuštěného modulu
    setActiveModule: (state, action: PayloadAction<string | undefined>) => {
      state.activeModuleId = action.payload;
    },
    // Akce pro uložení seznamu zaregistrovaných modulů
    setRegisteredModules: (state, action: PayloadAction<Array<RegisteredModule>>) => {
      state.registeredModules = action.payload;
    },
    // Akce pro uložení příznaku kompletního načtení všech registrací modulů
    setRegisteredModulesLoaded: state => {
      state.registeredModulesLoaded = true;
    },
    // Uložení registrované metody
    setModuleInterface: (
      state,
      action: PayloadAction<{
        moduleId: string;
        moduleInterface: ModuleInterface;
        version: string | undefined;
        buildNumber: string | undefined;
      }>
    ) => {
      state.registeredModules = state.registeredModules.map(module =>
        module.moduleId.toLocaleLowerCase() === action.payload.moduleId.toLocaleLowerCase()
          ? {
              ...module,
              interface: action.payload.moduleInterface,
              version: action.payload.version,
              buildNumber: action.payload.buildNumber,
            }
          : module
      );
    },
    // Potvrzení registrované metody - existuje platná komunikace
    confirmModuleInterfaceEvent: (
      state,
      action: PayloadAction<{
        moduleId: string;
        event: ModuleInterfaceEvent;
      }>
    ) => {
      const modules = state.registeredModules;
      modules.forEach(module => {
        module.interface?.events?.forEach(event => {
          if (
            !event.confirmed &&
            event.moduleId === action.payload.moduleId &&
            event.eventName === action.payload.event.eventName &&
            event.directionFlag !== action.payload.event.directionFlag
          ) {
            // eslint-disable-next-line no-param-reassign
            event.confirmed = true;
          }
        });
      });
      state.registeredModules = modules;
    },
    // Akce pro uložení použité platformy
    setPlatform: (state, action: PayloadAction<WebContainerPlatform>) => {
      storePlatform(action.payload);
      state.platform = action.payload;
    },
    // Akce pro uložení informace zda je použitá platforma aktivní (aplikace není pozastavena - uspána)
    setPlatformIsActive: (state, action: PayloadAction<boolean>) => {
      state.platformIsActive = action.payload;
    },
    // Příznak existence konfiguračního souboru
    setModuleConfigExists: (state, action: PayloadAction<string>) => {
      state.registeredModules = state.registeredModules.map(module =>
        module.moduleId.toLocaleLowerCase() === action.payload.toLocaleLowerCase()
          ? { ...module, configExists: true }
          : module
      );
    },
    // Nastavení zobrazení komponenty scanneru
    configureScanner: (state, action: PayloadAction<ScannerControl>) => {
      state.scannerSettings = action.payload;
    },
    // Zavření skeneru, zrušení nastavení
    clearScanner: state => {
      state.scannerSettings = undefined;
      state.scannerLastScan = undefined;
    },
    // Zavření nfc writeru, zrušení nastavení
    clearNfcWriter: state => {
      state.nfcWriterSettings = undefined;
    },
    // Uložení posledního naskenovaného kódu
    setScannerLastScan: (state, action: PayloadAction<IScan | undefined>) => {
      state.scannerLastScan = action.payload;
    },
    // Nastavení zobrazení komponenty NFC zápisu
    configureNfcWriter: (state, action: PayloadAction<NfcWriterControl>) => {
      state.nfcWriterSettings = action.payload;
    },
    // Kolekce nastavení dashboardu
    setDashboardSettings: (state, action: PayloadAction<Array<DashboardSettingsModel>>) => {
      state.dashboardSettings = action.payload;
    },
    // Akce pro uložení identifikátoru chyby pro účely vyvolání okna s detaily
    setOpenErrorDetailReference: (state, action: PayloadAction<string | undefined>) => {
      state.openErrorDetailReference = action.payload;
    },
    // Nastavení příznaku, že modul byl aktualizován.
    setModuleUpdated: (state, action: PayloadAction<boolean>) => {
      state.moduleUpdated = action.payload;
    },
    // Nastavení příznaku, že webcontainer byl aktualizován.
    setWebContainerUpdated: (state, action: PayloadAction<boolean>) => {
      state.webContainerUpdated = action.payload;
    },
    setAudioBuffer: (state, action: PayloadAction<Array<AudioEventType>>) => {
      state.audioBuffer = action.payload;
    },
  },
});

export const {
  setCulture,
  setNetworkStatus,
  setNetworkType,
  setNetworkStrength,
  setMachineSettings,
  setInfoPopupOpen,
  setMainMenuOpen,
  setActiveModule,
  setRegisteredModules,
  setRegisteredModulesLoaded,
  setModuleInterface,
  confirmModuleInterfaceEvent,
  setPlatform,
  setPlatformIsActive,
  setModuleConfigExists,
  configureScanner,
  clearScanner,
  clearNfcWriter,
  setScannerLastScan,
  configureNfcWriter,
  setDashboardSettings,
  setOpenErrorDetailReference,
  setModuleUpdated,
  setWebContainerUpdated,
  setAudioBuffer,
} = mainSlice.actions;
export default mainSlice;
