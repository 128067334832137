import React, { useMemo, useState } from "react";

// eslint-disable-next-line import/no-relative-packages
import "../../../node_modules/react-grid-layout/css/styles.css";
// eslint-disable-next-line import/no-relative-packages
import "../../../node_modules/react-resizable/css/styles.css";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { Backdrop, Box, CircularProgress, useMediaQuery } from "@mui/material";

import { useElementContext } from "@elx-element/common";
import { apiMan } from "@elx-element/common/apiClient/apiMan";
import { HapticsImpactStyle, NetworkStatus, Notification } from "@elx-element/common/enums";
import { dispatchHapticsEvent, dispatchNotificationEvent } from "@elx-element/common/events/dispatchers";
import { NotificationEventModel } from "@elx-element/common/events/types";
import { RegisteredModule } from "@elx-element/common/types";
import { cloneArray, format, isNullOrUndefinedOrEmpty } from "@elx-element/common/utils";
import BaseIcon from "@elx-element/ui/DataDisplay/BaseIcon";
import SettingsButton from "@elx-element/ui/Utils/SettingsButton";

import { CustomModuleGridData } from "../../types";
import { ConfigurationClient, DashboardSettingsModel } from "../../types.generated";

import {
  selectCulture,
  selectDashboardSettings,
  selectMachineSettings,
  selectNetworkStatus,
  selectRegisteredModules,
  selectRegisteredModulesLoaded,
} from "../../store/main/selectors";
import { setDashboardSettings } from "../../store/main/slice";

import useAbortController from "../../hooks/useAbortController";
import useTexts from "../../hooks/useTexts";

import useStyles from "./styles";

import IncompatibilityIcon from "./IncompatibilityIcon";
import WidgetLoader from "./WidgetLoader";
import WidgetSelector from "./WidgetSelector";

import Theme from "../../theme";
import MachineSettings from "../machineSettings";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { classes, cx } = useStyles();
  const texts = useTexts();
  const culture = useSelector(selectCulture);
  const registeredModules = useSelector(selectRegisteredModules);
  const registeredModulesLoaded = useSelector(selectRegisteredModulesLoaded);
  const viewPortTablet = useMediaQuery(Theme.breakpoints.down("md"));
  const ResponsiveGridLayout = WidthProvider(Responsive);
  const dashboardSettings = useSelector(selectDashboardSettings);
  const { checkTokenAnyRoleExists } = useElementContext();
  const abortController = useAbortController();

  const widgetPath =
    window.env.webcontainer.LOAD_SERVER_MODULES && window.env.webcontainer.SERVER_MODULES_PATH
      ? window.env.webcontainer.SERVER_MODULES_PATH
      : undefined;
  const client = new ConfigurationClient({ abortSignal: abortController.signal });

  const [isDragable, setDragable] = useState<boolean>(false);
  const [openSettingsDrawer, setOpenSettingsDrawer] = useState<boolean>(false);
  const machineSettings = useSelector(selectMachineSettings);

  const networkStatus = useSelector(selectNetworkStatus);
  const isOffline = networkStatus === NetworkStatus.offline;

  // Pokud se jedná o externí modul, k němuž chybí konfigurace, modul je blokován.
  const handleNavLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, m: RegisteredModule) => {
    if (isDragable || m.licenseExpired()) {
      event.preventDefault();
      return;
    }
    if (m.configExists === false && m.externalModule === true) {
      event.preventDefault();
      dispatchNotificationEvent(
        new NotificationEventModel(format(texts.CONFIG_FILE_MISSING, m.moduleId), Notification.warning)
      );
      return;
    }
    dispatchHapticsEvent({ impactOptions: { style: HapticsImpactStyle.light } });
  };

  /**
   * Update stavu widgetu a uložení do nastavení uživatele v databázi.
   * @param settings
   */
  const onWidgetChange = (settings: DashboardSettingsModel) => {
    const newDashboardSettings = cloneArray(dashboardSettings);
    const index = dashboardSettings.findIndex(x => x.moduleId === settings.moduleId);
    if (index > -1) {
      // uživatelské nastavení existuje
      newDashboardSettings[index] = settings;
    } else {
      // záznam neexistuje
      newDashboardSettings.push(settings);
    }
    const originalSettings = dashboardSettings[index];
    if (JSON.stringify(originalSettings) !== JSON.stringify(settings)) {
      apiMan(client.updateDashboardSettings(settings.moduleId!, settings));
      dispatch(setDashboardSettings(newDashboardSettings));
    }
  };

  /**
   * Update stavu rozložení dashboardu a uložení do nastavení uživatele v databázi.
   * Uložení probíhá pouze pokud je povolen přesun dlaždic přepínačem.
   * @param settings
   */
  const onLayoutChange = (layout: ReactGridLayout.Layout[]) => {
    if (isDragable) {
      const newDashboardSettings = cloneArray(dashboardSettings);
      layout.forEach(element => {
        const index = dashboardSettings.findIndex(x => x.moduleId === element.i);
        const settings: DashboardSettingsModel = {
          widgetId: undefined,
          moduleId: element.i,
          positionX: element.x,
          positionY: element.y,
        };
        if (index > -1) {
          // uživatelské nastavení existuje
          settings.widgetId = newDashboardSettings[index].widgetId;
          newDashboardSettings[index] = settings;
        } else {
          // záznam neexistuje
          newDashboardSettings.push(settings);
        }
        const originalSettings = dashboardSettings[index];
        if (JSON.stringify(originalSettings) !== JSON.stringify(settings)) {
          apiMan(client.updateDashboardSettings(settings.moduleId!, settings));
        }
      });
      if (JSON.stringify(newDashboardSettings) !== JSON.stringify(dashboardSettings)) {
        dispatch(setDashboardSettings(newDashboardSettings));
      }
    }
  };

  /**
   * Nastavení jednotlivých dlaždic. Pokud existuje uživatelské nastavení dlaždice, použije se.
   * Namobilech a malých tabletech se uživatelksé nastavení nezohledňuje.
   * @param moduleId
   * @param idx
   * @returns Nastavení dlaždice
   */
  const getCustomModuleGridData = (moduleId: string, idx: number): CustomModuleGridData => {
    let customModuleGridData: CustomModuleGridData;
    // na mobilních zařízeních se renderuje v defaultním pořadí
    if (dashboardSettings.length > 0) {
      // nastavení konkrétní dlaždice aplikace
      const customSettings = dashboardSettings.find(x => x.moduleId === moduleId);
      // existuje-li uživatelské nastavení dlaždice
      if (customSettings) {
        const widget = registeredModules
          .find(x => x.moduleId === moduleId)
          ?.widgets?.find(x => x.id === customSettings!.widgetId);
        customModuleGridData = {
          // nastavení widgetu, pokud existuje
          widget,
          // pokud se jedná o widget, renderuje se na řádku 3, dlaždice na řádku 1
          // dlaždice má defaultní velikost 2x2
          layout: {
            i: moduleId,
            x: customSettings!.positionX,
            y: customSettings!.positionY,
            w: customSettings!.widgetId ? widget!.dimensionX : 1,
            h: customSettings!.widgetId ? widget!.dimensionY : 1,
          },
        };
      } else {
        // neznámé nastavení dlaždice modulu - defaultní pořadí
        customModuleGridData = {
          layout: { i: moduleId, x: idx, y: 1, w: 1, h: 1 },
        };
      }
    }
    // neexistuje li žádné uživatelské nastavení, vykreslují se jen dlaždice v defaultním pořadí
    else {
      customModuleGridData = {
        layout: { i: moduleId, x: idx, y: 1, w: 1, h: 1 },
      };
    }

    // úprava pozic dlaždic na malých displejích
    if (viewPortTablet) {
      customModuleGridData.layout.x %= 2;
    }

    return customModuleGridData;
  };

  // Render funkce obsahu dlaždice/widgetu
  const renderTile = (m: RegisteredModule, customModuleGridData: CustomModuleGridData) => (
    <>
      {customModuleGridData.widget === undefined && (
        <>
          {!isDragable && (
            <IncompatibilityIcon
              moduleId={m.moduleId}
              showOptionalAlerts={machineSettings.incompatibilityAlertsEnabled}
            />
          )}
          <BaseIcon className="tileIcon" data={m.tileIcon ?? ""} />
          <span>{m.getLocalizedName(culture)}</span>
        </>
      )}
      {customModuleGridData.widget !== undefined && (
        <>
          <div
            className={cx(classes.widgetHeader, isDragable ? classes.widgetHeaderDragged : undefined)}
            style={!isNullOrUndefinedOrEmpty(m.tileColor) ? { backgroundColor: m.tileColor } : undefined}
          >
            {!isDragable && (
              <IncompatibilityIcon
                moduleId={m.moduleId}
                showOptionalAlerts={machineSettings.incompatibilityAlertsEnabled}
              />
            )}
            <BaseIcon className="tileIconSmall" data={m.tileIcon ?? ""} />
            <span className="tileSpanSmall">{m.getLocalizedName(culture)}</span>
          </div>
          {!isDragable && m.configExists && (
            <WidgetLoader
              moduleId={m.moduleId}
              widgetRoute={customModuleGridData.widget.widgetRoute!}
              widgetPath={widgetPath}
            />
          )}
        </>
      )}
    </>
  );

  const dashboardContent = useMemo(
    () =>
      registeredModules
        .filter(
          x =>
            ((viewPortTablet && x.allowOnMobile) || !viewPortTablet) &&
            (!x.requiredPermission || checkTokenAnyRoleExists(x.requiredPermission!))
        )
        .map((m, idx) => {
          const customModuleGridData = getCustomModuleGridData(m.moduleId, idx);
          return (
            <Box
              key={m.moduleId}
              className={cx(classes.widget, customModuleGridData.widget ? classes.noPaddding : undefined)}
              style={!isNullOrUndefinedOrEmpty(m.tileColor) ? { backgroundColor: m.tileColor } : undefined}
              data-grid={customModuleGridData.layout}
              sx={{ boxShadow: 1 }}
            >
              {isDragable && m.widgets !== undefined && m.widgets.length > 0 && (
                <WidgetSelector
                  className={classes.widgetSelector}
                  module={m}
                  customModuleGridData={customModuleGridData}
                  onWidgetChange={onWidgetChange}
                />
              )}
              {isDragable && <div className={classes.dragedBox}>{renderTile(m, customModuleGridData)}</div>}
              {!isDragable && (
                <NavLink
                  to={`/${m.route ?? m.moduleId}`}
                  onClick={event => handleNavLinkClick(event, m)}
                  className={cx(classes.navLink, isOffline || m.licenseExpired() ? classes.notAllowed : undefined)}
                >
                  {renderTile(m, customModuleGridData)}
                </NavLink>
              )}
            </Box>
          );
        }),
    [registeredModules, viewPortTablet, isDragable]
  );

  return (
    <>
      {!isOffline && !viewPortTablet && registeredModulesLoaded && (
        <SettingsButton onClick={() => setOpenSettingsDrawer(true)} />
      )}
      {registeredModulesLoaded && (
        <ResponsiveGridLayout
          useCSSTransforms={false}
          isResizable={false}
          isDraggable={isDragable}
          cols={{ lg: 10, md: 8, sm: 4, xs: 2, xxs: 2 }}
          rowHeight={120}
          onLayoutChange={l => onLayoutChange(l)}
          autoSize
        >
          {dashboardContent}
        </ResponsiveGridLayout>
      )}

      <MachineSettings
        open={openSettingsDrawer}
        dashboardControl={[isDragable, setDragable]}
        onClose={() => setOpenSettingsDrawer(false)}
      />

      {!registeredModulesLoaded && (
        <Backdrop open>
          <CircularProgress />
        </Backdrop>
      )}
    </>
  );
};

export default Dashboard;
