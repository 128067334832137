import { Dispatch, useEffect, useMemo, useState } from "react";

import { useSelector } from "react-redux";

import { apiMan } from "@elx-element/common/apiClient/apiMan";
import { NetworkStatus, Notification } from "@elx-element/common/enums";
import { getConfigurationStringValue } from "@elx-element/common/envconf";
import { dispatchNotificationEvent } from "@elx-element/common/events/dispatchers";
import { NotificationEventModel, SwitchModuleEventModel } from "@elx-element/common/events/types";
import { throwSpecificError } from "@elx-element/common/logger";

import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IHttpConnectionOptions,
  ILogger,
  LogLevel,
} from "@microsoft/signalr";

import { MessageEchoModel } from "../types";
import { MessageModel, NotificationModel, WebContainerClient } from "../types.generated";

import { selectNetworkStatus, selectPlatformIsActive } from "../store/main/selectors";
import { addMessage, addNotification, addUsers } from "../store/message/slice";

import { SystemActionCode, WebContainerErrors } from "../enums";

const debug = window.env.webcontainer.ENABLE_DEBUG_LOG ?? false;

function initSignalR(token: string | undefined, logger: ILogger): HubConnection {
  const options: IHttpConnectionOptions = {
    accessTokenFactory: () => token ?? "",
    logger,
  };
  return new HubConnectionBuilder()
    .withUrl(`${getConfigurationStringValue(window.env.webcontainer, "API_URL")}/notificationHub`, options)
    .withAutomaticReconnect()
    .build();
}

const hubOnCloseCallback = (error?: Error | undefined): void => {
  if (debug) {
    console.debug("[Element] SignalR closed with error.", error);
  }
};

async function startConnection(hub: HubConnection): Promise<void> {
  hub.onclose(hubOnCloseCallback);
  if (debug) {
    console.debug("[Element] SignalR wakes up...");
  }
  await hub.start();
}

function subscribeToNotifications(
  hub: HubConnection | undefined,
  onNewMessageMethod: (data: MessageEchoModel) => void
): void {
  hub?.on("NewItemEcho", (messageId: number, messageType: string) => onNewMessageMethod({ messageId, messageType }));
}

export async function stopConnection(connection: HubConnection | undefined): Promise<void> {
  if (connection === undefined) {
    return;
  }
  if (connection.state === HubConnectionState.Connected) {
    if (debug) {
      console.debug("[Element] SignalR is falling asleep...");
    }
    await connection.stop();
  } else if (
    connection.state !== HubConnectionState.Disconnected &&
    connection.state !== HubConnectionState.Disconnecting
  ) {
    // Timeout je použit aby se dokončilo připojování, jinak by vyskočila chyba, kdyby se zastavilo připojení, které ještě není aktivní
    setTimeout(() => {
      if (debug) {
        console.debug("[Element] SignalR is falling asleep...");
      }
      stopConnection(connection);
    }, 1000);
  }
}
/**
 * Singalr v rámci připojování a realizace spojení kromě vyhazování exception posílá zpravý i pomocí logger.error
 * Děje se to hlavně v okamžiku, kdy spojení je realizováno, ale dochází k výpadku až posléze (spojeni ješte není
 * realizováno komplet, ale už je navázáno)
 * Protože v elementu se snažíme zachytávat všechny chyby skrz konzole error, byl nahrazen default logger u signaleru
 * naším loggerem, který chyby archivuje, ale nevyhazuje.
 * Upozornění na chybu je umístěno u "Reakce na chyby".
 *
 * @returns
 */

export const useLogger = () => {
  const [errors, setErrors] = useState([] as Error[]);
  const logger = useMemo(() => {
    const level = debug ? LogLevel.Debug : LogLevel.Information;
    let hErrors = [] as Error[];
    return {
      clear: () => {
        hErrors = [];
        setErrors(hErrors);
      },
      addError(e: Error) {
        hErrors = [...hErrors, e];
        setErrors(hErrors);
      },
      log(logLevel: LogLevel, message: string) {
        switch (logLevel) {
          case LogLevel.Error:
          case LogLevel.Critical:
          case LogLevel.Warning:
            hErrors = [...hErrors, new Error(message)];
            setErrors(hErrors);
            return;
          default:
        }
        // eslint-disable-next-line no-bitwise
        if (level & logLevel) {
          switch (logLevel) {
            case LogLevel.Debug:
              console.debug(message);
              break;
            case LogLevel.Trace:
              // eslint-disable-next-line no-console
              console.trace(message);
              break;
            default:
              // eslint-disable-next-line no-console
              console.log(message);
          }
        }
      },
    };
  }, []);
  return { logger, errors };
};

export const useElementMessagesChecker = (
  token: string | undefined,
  client: WebContainerClient,
  newMessageText: string,
  dispatch: Dispatch<any>
): HubConnection | undefined => {
  const networkStatus = useSelector(selectNetworkStatus);
  const platformIsActive = useSelector(selectPlatformIsActive);
  const [connection, setConnection] = useState<HubConnection | undefined>(undefined);
  const [connectionAllow, setConnectionAllow] = useState<boolean>(true);
  const [allowTimeout, setAllowTimeout] = useState(true);
  const { logger, errors } = useLogger();

  /**
   * Reakce na chyby
   */
  useEffect(() => {
    if (errors.length === 20) {
      throwSpecificError(WebContainerErrors.signalRError, "[Element SignalR]: Signal connection error", errors[19]);
    }
  }, [errors]);

  useEffect(() => {
    if (platformIsActive) {
      setConnectionAllow(true);
    } else {
      setConnectionAllow(false);
    }
  }, [platformIsActive]);

  /**
   * SignalR messaging
   */
  useEffect(() => {
    // Při výpadku sítového spojení, vypnutém displaji, nebo uspání aplikace není komunikace povolena.
    if (!token || !connectionAllow || networkStatus === NetworkStatus.offline || !allowTimeout) {
      return;
    }

    const connection2 = initSignalR(token, logger);
    setConnection(connection2);

    // eslint-disable-next-line consistent-return
    return () => {
      setConnection(undefined);
      async function stop() {
        await stopConnection(connection2);
      }
      stop();
    };
  }, [token, connectionAllow, networkStatus, allowTimeout]);

  /**
   * Rutina při obnovení/navázání spojení webSocket
   */
  useEffect(() => {
    // není důvod cokoliv řešit
    if (!connection) {
      return;
    }

    const ReconnectTimeout = 30000;

    /**
     * Callback pro ošetření chyb
     * @param error
     */
    const hubOnReconnectionCallback = (error?: Error | undefined): void => {
      // Při nuceném odříznutí služby (restart kontejneru v azure) je ukončeno spojení SignaleR.
      // Webcontainer automaticky restartuje spojení.
      // Chyby není potřeba logovat hned.
      if (error /* ?.message.includes("WebSocket closed with status code: 1006 ().") */) {
        logger.addError(error);
        if (debug) {
          console.debug("[Element SignalR]: Webcontainer SignalR is restarting...");
        }
        connection.stop();
        setAllowTimeout(() => {
          setTimeout(() => setAllowTimeout(true), ReconnectTimeout);
          return false;
        });
      }
    };

    async function connect(hub: HubConnection) {
      hub.onreconnecting(hubOnReconnectionCallback);

      try {
        await startConnection(hub);
        subscribeToNotifications(hub, onNotificationRecieved);
        logger.clear();
      } catch (e) {
        (e as any).catched = true;
        logger.addError(e as Error);
        setAllowTimeout(false);
        setTimeout(() => setAllowTimeout(true), ReconnectTimeout);
      }
    }

    if (connection.state === HubConnectionState.Disconnected) {
      connect(connection);
    }
  }, [connection]);

  /**
  );
   * Metoda pro načtení zpráv uživatele
   */
  const loadMessage = async (data: MessageEchoModel): Promise<void> => {
    const message: MessageModel | undefined = await apiMan(client.getMessageDetail(data.messageId));
    if (message) {
      dispatchNotificationEvent(new NotificationEventModel(newMessageText, Notification.info, message.description));
      dispatch(addMessage(message));
    }
  };

  /**
   * Metoda pro načtení notifikací uživatele.
   * Notifikace se zobrazí v podobě snackbaru a uloží se do popupu notifikací.
   */
  const loadNotification = async (data: MessageEchoModel): Promise<void> => {
    const notification: NotificationModel | undefined = await apiMan(client.notification(data.messageId));
    if (notification) {
      if (
        notification.code === undefined ||
        !Object.values(SystemActionCode).includes(notification.code as SystemActionCode)
      ) {
        // pokud se nejedná o systémovou notifikaci vygeneruje banner a ikonu s notifikací
        dispatchNotificationEvent(
          new NotificationEventModel(notification.title, Notification.info, notification.description)
        );
        dispatch(addNotification(notification));
      }
      processNotificationAction(notification);
    }
  };

  /**
   * @param messageId id zprávy
   * @param messageType typ zprávy (Notifikace/zpráva)
   */
  const onNotificationRecieved = (data: MessageEchoModel): void => {
    if (data.messageType === "message") {
      loadMessage(data);
    } else {
      loadNotification(data);
    }
  };

  /**
   * Spuštění akce připojené k notifikaci. Akce se spouští automaticky při přijetí.
   * @param notification
   */
  const processNotificationAction = async (notification: NotificationModel) => {
    // zpracování systémové akce
    if (notification.code && Object.values(SystemActionCode).includes(notification.code as SystemActionCode)) {
      switch (notification.code) {
        case SystemActionCode.userlistchanged:
          {
            const users = await apiMan(client.user(1000, 0)); /// událost update seznamu uživatelů
            if (users) {
              dispatch(addUsers(users));
            }
          }
          break;
        default:
          break;
      }
    } else {
      // zpracování nesystémové akce
      let actionModel: SwitchModuleEventModel | undefined;
      try {
        if (notification.action && notification.action.length > 0) {
          actionModel = JSON.parse(notification.action!);
        }
      } catch {
        throwSpecificError(
          WebContainerErrors.unsupportedActionData,
          "[Element Signaler]: Action data error.",
          `notificationId: ${notification.id}, actionData: ${notification.action}`
        );
      }
      if (actionModel) {
        throwSpecificError(
          WebContainerErrors.unsupportedActionData,
          "[Element Signaler]: NotImplementedError.",
          `notificationId: ${notification.id}, actionData: ${actionModel}`
        );
      }
    }
  };
  return connection;
};
